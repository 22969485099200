import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DateTime } from "luxon";
import { capitalize, pick } from "radash";
import {
  Link as RRDLink,
  useLoaderData,
  useSearchParams,
} from "react-router-dom";
import { get } from "../apiClient";
import humanize from "../features/segments/utils/humanize";
import formatExecutionTime from "../features/targeting_jobs/utils/formatExecutionTime";
import formatStateDateTime from "../features/targeting_jobs/utils/formatStateDateTime";
import useTitle from "../hooks/useTitle";
import formatDateTime from "../utils/formatDateTime";

const Link = styled(RRDLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
}));

const PAGINATION_DEFAULTS = {
  limit: "25",
  offset: "0",
  orderBy: "id",
  orderDirection: "desc",
};

export const loader = async ({ request }) => {
  const searchParams = Object.fromEntries(new URL(request.url).searchParams);

  const { result: targetingJobs, total_count: totalCount } = await get(
    "audience",
    "/v1/segments/targeting_jobs",
    {
      params: {
        pagination: {
          ...PAGINATION_DEFAULTS,
          ...pick(searchParams, Object.keys(PAGINATION_DEFAULTS)),
        },
      },
      signal: request.signal,
    }
  );

  return { targetingJobs, totalCount };
};

const TargetingJobs = () => {
  useTitle("Targeting Jobs");
  const [searchParams, setSearchParams] = useSearchParams(PAGINATION_DEFAULTS);
  const { targetingJobs, totalCount } = useLoaderData();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Attempts</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Segment ID</TableCell>
                    <TableCell>S3 Key</TableCell>
                    <TableCell>Execution Time</TableCell>
                    <TableCell>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {targetingJobs.map((targetingJob) => (
                    <TableRow key={targetingJob.id}>
                      <TableCell>{targetingJob.id}</TableCell>
                      <TableCell>
                        {formatDateTime(
                          DateTime.fromISO(targetingJob.created_at)
                        )}
                      </TableCell>
                      <Tooltip title={formatStateDateTime(targetingJob)}>
                        <TableCell>{capitalize(targetingJob.state)}</TableCell>
                      </Tooltip>
                      <TableCell>{targetingJob.attempt}</TableCell>
                      <TableCell>
                        {targetingJob.type === "association"
                          ? "Add identifiers"
                          : "Remove identifiers"}
                      </TableCell>
                      <TableCell>
                        {targetingJob.segment && (
                          <Link to={`/segments/${targetingJob.segment.id}`}>
                            {humanize(targetingJob.segment)}
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>{targetingJob.s3_object}</TableCell>
                      <TableCell>{formatExecutionTime(targetingJob)}</TableCell>
                      <TableCell>{targetingJob.error || "N/A"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalCount}
              onPageChange={(_event, page) => {
                setSearchParams((prev) => ({
                  ...Object.fromEntries(prev),
                  offset: Math.max(page * Number(prev.get("limit")), 0),
                }));
              }}
              onRowsPerPageChange={(event) => {
                setSearchParams((prev) => ({
                  ...Object.fromEntries(prev),
                  limit: event.target.value,
                }));
              }}
              page={
                Number(searchParams.get("offset")) /
                Number(searchParams.get("limit"))
              }
              rowsPerPage={Number(searchParams.get("limit"))}
              rowsPerPageOptions={[25, 50, 100]}
            />
          </>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TargetingJobs;
